<template>
  <v-row class="flex-wrap justify-center align-center fixed">
    <Overlay :loading="isLoading" />
    <v-col cols="6" class="pa-0">
      <v-row class="justify-center">
        <div class="column align-self-center">
          <v-row class="align-center mb-10">
            <v-img
              width="80"
              src="../../assets/logoMis.png"
            ></v-img>
          </v-row>
          <v-row class="column">
            <!-- Doctor -->
            <div>
              <div class="subtitle-2 font-weight-regular gray--text">
                <v-btn
                  text
                  color="gray"
                  class="pa-0"
                  @click="
                    $router.push({
                      path: '/',
                    })
                  "
                >
                  <v-icon>mdi-arrow-left</v-icon> Voltar
                </v-btn>
              </div>
              <div class="text-h5 font-weight-bold mt-3">Login</div>
              <div class="button mt-2 logo">
                Login para profissional de atendimento
              </div>
              <div class="mt-5 containerInput">
                <v-text-field
                  label="CPF"
                  placeholder="Insira seu CPF"
                  outlined
                  color="#1c3b7b"
                  flat
                  v-mask="'###.###.###-##'"
                  v-model="cpf"
                  @keyup.enter="authenticate"
                ></v-text-field>
                <v-text-field
                  label="Senha"
                  placeholder="Insira sua senha"
                  outlined
                  color="#1c3b7b"
                  flat
                  type="password"
                  v-model="password"
                  @keyup.enter="authenticate"
                ></v-text-field>
                <v-btn
                  depressed
                  width="100%"
                  color="#1C3B7B"
                  class="pa-6 white--text"
                  @click="authenticate"
                >
                  Entrar
                </v-btn>
              </div>
              <div class="text-center mt-10">
                <v-btn
                  class="text-decoration-underline pa-0 ma-0"
                  color="#686868"
                  style="font-size: 0.68rem" 
                  text
                >
                  Política de Privacidade e Termos de Serviço
                </v-btn>
              </div>
            </div>
          </v-row>
        </div>
      </v-row>
    </v-col>
     <v-col cols="6" class="pa-0" v-if="!$vuetify.breakpoint.mobile">
      <v-img
        class="wallpaper"
        :lazy-src="require('../../assets/login.png')"
        :src="require('../../assets/login.png')"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import Overlay from "../../components/Overlay/Overlay.vue";

export default {
  components: {
    Overlay,
  },
  data: () => ({
    cpf: "",
    password: "",
    isLoading: false,
  }),
  methods: {
    ...mapActions("authentication", ["validateSession", "login"]),
    async authenticate() {
      try {
        this.isLoading = true;
        await this.login({ cpf: this.cpf, password: this.password });
        this.$router.push({ name: "Dashboard" });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
.logo {
  color: #1c3b7b;
}
.containerInput {
  width: 310px;
}
.fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.wallpaper {
  background-image: url("../../assets/login.png");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  flex: 1;
}
</style>
